<template>
    <div>
        <el-dialog :visible.sync="sta.show">
            <span slot="title">批量新增子项</span>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label><b>项目名称</b> (多个子项请用回车换行)</label>
                        <el-input type="textarea" :rows="5" v-model="model.NAMES" />
                    </div>
                </div>
            </div>

            <div slot="footer" v-loading="sta.loading">
                <el-button icon="el-icon-close" @click="sta.show = false">取消</el-button>
                <el-button icon="el-icon-check" type="success" @click="submit">确认</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                sta: {
                    show: false,
                    loading: false
                },
                model: {
                    NAMES: "",
                    TYPE_ID: 0
                },
            }
        },
        methods: {
            init(model) {
                this.sta = { show: true, loading: false }
                this.model.TYPE_ID = model.TYPE_ID;
            },
            submit() {
                let self = this;
                let names = self.model.NAMES.split("\n")

                if (!self.model.NAMES) {
                    self.$message.warning('请填写项目名称！')
                    return
                }
                self.sta.loading = true;
                this.whale.remote.getResult({
                    url: "/api/School/INS/TypeApi/SaveItems",
                    data: { TYPE_ID: self.model.TYPE_ID, NAMES: JSON.stringify(names) },
                    finally() { self.sta.loading = false; },
                    completed: function (res) {
                        if (res.SUCCESS) {
                            self.sta.show = false;
                            self.model.NAMES = "";
                            self.$emit("on-saved");
                        } else {
                            self.$message.error(res.MESSAGE)
                        }
                    }
                })
            }
        }
    }
</script>